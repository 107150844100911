<template>
    <div>
        <header>
            <div class="navbar">
                <!-- <div class="logo">
                    <el-image style="width: 188px;height: 60px;"
                        src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/133a5b86-8d8c-46b1-9cf8-3fb2a0337619.png"
                        fit="fit">
                    </el-image>
                </div> -->



                <div class="navbarChoose">
                    <el-menu background-color="transparent" :default-active="activeIndex" class="el-menu-demo"
                        mode="vertical" text-color="white" @select="handleSelect" router
                        style="border-right: none !important;">
                        <!-- 可以修改menu的颜色 text-color="red" active-text-color="green" -->
                        <el-menu-item class="menuitem" index="/">首页</el-menu-item>
                        <el-submenu class="menuitem" index="/brand">
                            <template slot="title">品牌中心</template>
                            <el-menu-item index="/brand/companyStrength">公司实力</el-menu-item>
                            <el-menu-item index="/brand/companyCulture">企业文化</el-menu-item>
                            <el-menu-item index="/brand/companyFounder">创始人介绍</el-menu-item>
                            <el-menu-item index="/brand/companyShowroom">展厅</el-menu-item>
                            <el-menu-item index="/brand/companyPatent">专利证书</el-menu-item>
                            <el-menu-item index="/brand/companyNews">公司新闻</el-menu-item>
                        </el-submenu>
                        <el-submenu class="menuitem" index="/productS">
                            <template slot="title">产品中心</template>
                            <el-menu-item index="/product/ty">庭院门</el-menu-item>
                            <el-menu-item index="/product/xt">系统进户门</el-menu-item>
                            <el-menu-item index="/product">全部产品</el-menu-item>
                        </el-submenu>
                        <el-menu-item class="menuitem" index="/class">英伦课堂</el-menu-item>
                        <el-menu-item class="menuitem" index="/custom">我要定制</el-menu-item>
                        <el-menu-item class="menuitem" index="/case">工程案例</el-menu-item>
                        <el-menu-item class="menuitem" index="/store">网点查询</el-menu-item>
                        <el-menu-item class="menuitem" index="/investment">招商加盟</el-menu-item>

                    </el-menu>
                </div>


                <div class="bottombar">

                    <div class="barimg">
                        <el-image style="width: 20vw;"
                            src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-07/1cfe13c7-7872-48be-84ee-a27872426c8d.png"></el-image>
                        <div>英伦风尚官方微信</div>
                    </div>

                </div>
            </div>

        </header>

    </div>
</template>
<script>
export default {
    name: 'NavComA',
    data() {
        return {
            activeIndex: '/',
            isCollapse: true
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);

            console.log("handleSelect    " + this.activeIndex + "    " + this.$route.path);
        }
    },
    mounted() {
        this.activeIndex = this.$route.path
        console.log("mounted    " + this.activeIndex + "    " + this.$route.path + "  " + this.$route.meta);
    },
    watch: {
        $route(now, old) {
            // now: 变化之后的数据
            // old: 变化之前的数据
            console.log(this.$route.path + "      navcom地址栏         " + now, old)
            this.activeIndex = this.$route.path;
        }
    }
}
</script>


<style scoped lang="scss">
  .bottombar {
    width: 100%;
    height: 33vw;
    background-color: #1A1A1A;
    display: flex;

    .bars {
      width: 75%;
      margin: auto;
      text-align: start;

      .upbars {
        height: 55px;
        width: 50%;
        display: flex;
        margin-left: 10vw;
        color: #DADADA;

        div {
          margin-right: 60px;
          width: 65px;
          border-bottom: 2px #DADADA solid;
        }
      }

      .downbars {

        width: 50%;
        display: flex;
        margin-left: 10vw;
        margin-top: 20px;
        color: #787878;
        font-size: 14px;

        div {
          margin-right: 60px;
          width: 65px;

          div:hover {
            background-color: white;
          }
        }

      }

      .line_bar {
        height: 1px;
        background-color: #545454;
      }
    }

    .barimg {
      margin: auto;
      color: #9A9A9A;
      div{
        font-size: 10px;
      }
    }

    .endline {
      margin: auto;

      width: 15%;

      .line_bar {
        height: 1px;
        background-color: #545454;
      }
    }
  }

header {
    background-color: #030408;
}

.navbarChoose {
    margin: auto;
}

.navbar {

    color: white;
    width: 100%;
    margin: auto;
    z-index: 999;
    /* border-bottom: solid 1px #e6e6e6; */
}

.logo {

    margin: auto;
}

.line {
    height: 1px;
    background-color: #e0e6ed;
    width: 100%;
}



.el-menu-demo {
    text-align: start;
    width: 80vw;
    margin: auto;
}

.menuitem {
    border-bottom: solid 1px #e0e6ed;
}
</style>