<template>
    <div>
        <header>
            <div class="navbar">
                <div class="logo">
                    <el-image style="width: 188px;height: 60px;"
                        src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/133a5b86-8d8c-46b1-9cf8-3fb2a0337619.png"
                        fit="fit">
                    </el-image>
                </div>

                <!-- <div class="navbarChoose">
                    <el-menu background-color="transparent" :default-active="activeIndex" class="el-menu-demo"
                        mode="vertical" @select="handleSelect" router >
                        可以修改menu的颜色 text-color="red" active-text-color="green"
                        <el-menu-item index="/">首页</el-menu-item>
                        <el-submenu index="/brand">
                            <template slot="title">品牌中心</template>
                            <el-menu-item index="/brand/companyStrength">公司实力</el-menu-item>
                            <el-menu-item index="/brand/companyCulture">企业文化</el-menu-item>
                            <el-menu-item index="/brand/companyFounder">创始人介绍</el-menu-item>
                            <el-menu-item index="/brand/companyShowroom">展厅</el-menu-item>
                            <el-menu-item index="/brand/companyRandDCenter">研发中心</el-menu-item>
                            <el-menu-item index="/brand/companyPatent">专利证书</el-menu-item>
                            <el-menu-item index="/brand/companyNews">公司新闻</el-menu-item>
                        </el-submenu>
                        <el-submenu index="/productS">
                            <template slot="title">产品中心</template>
                            <el-menu-item index="/product/ty">庭院门</el-menu-item>
                            <el-menu-item index="/product/xt">系统进户门</el-menu-item>
                            <el-menu-item index="/product">全部产品</el-menu-item>
                        </el-submenu>
                        <el-menu-item index="/class">英伦课堂</el-menu-item>
                        <el-menu-item index="/custom">我要定制</el-menu-item>
                        <el-menu-item index="/case">工程案例</el-menu-item>
                        <el-menu-item index="/store">网点查询</el-menu-item>
                        <el-menu-item index="/investment">招商加盟</el-menu-item>

                    </el-menu>
                </div> -->
            </div>

        </header>
  
    </div>
</template>
<script>
export default {
    name: 'NavCom',
    data() {
        return {
            activeIndex: '/',
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);

            console.log("handleSelect    " + this.activeIndex + "    " + this.$route.path);
        }
    },
    mounted() {
        this.activeIndex = this.$route.path
        console.log("mounted    " + this.activeIndex + "    " + this.$route.path + "  " + this.$route.meta);
    },
    watch: {
        $route(now, old) {
            // now: 变化之后的数据
            // old: 变化之前的数据
            console.log(this.$route.path + "      navcom地址栏         " + now, old)
            this.activeIndex = this.$route.path;
        }
    }
}
</script>


<style scoped lang="scss">
header{
    background-color: #030408;
}
.navbarChoose {
  
}

.navbar {
    display: flex;
    color: white;
    width: 100%;
    margin: auto;
    z-index: 999;
    /* border-bottom: solid 1px #e6e6e6; */
}

.logo {

  margin: auto;
}

.line {
    height: 1px;
    background-color: #e0e6ed;
    width: 100%;
}

.el-menu {
    border-bottom: none;
}
</style>