<template>
  <div class="home">
    <video autoplay="autoplay" style="width: 100%;" loop="loop" controls muted>
      <source
        src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayVideo/2024-03-11/7337f9aa-6579-4f35-887b-99dacf2a73d5.mp4"
        type="video/mp4">
    </video>
    <div class="series">
      <div class="series_title">
        <div class="series_title_big">产品系列</div>
        <div class="series_title_small">多种系列风格满足你的选择</div>
      </div>
      <div class="category">
        <div class="line"></div>
        <div class="btn" :class="categoryAactive === 0 ? 'cActive' : ''" @click="switchTab(0)">庭院门</div>
        <div class="btn" :class="categoryAactive === 1 ? 'cActive' : ''" @click="switchTab(1)">系统进户门</div>
        <div class="line"></div>
      </div>

      <div class="little_category">
        <ul class="ul_imgs">
          <li v-for="(item, index) in categoryimg[categoryAactive]" :key="index"
            :class="categoryBactive === index ? 'active' : ''" @click="switchSecondT(index)">

            <el-avatar :size="80" :src="item.url" fit="cover"></el-avatar>
            <div>{{ item.name }}</div>

          </li>

        </ul>
      </div>


      <div class="ul_big_imgs" v-bind:style="{
        backgroundImage: 'url(' + categoryimg[categoryAactive][categoryBactive].bigurl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }">
        <!-- <el-image style="width: 100%;"
          :src="categoryimg[categoryAactive][categoryBactive].bigurl"
          fit="fit"></el-image> -->
        <div class="ul_big_imgs_part1">{{ categoryimg[categoryAactive][categoryBactive].text }}</div>
        <div class="ul_big_imgs_part2">
          <div>
            <el-image style="width: 188px;height: 60px;"
              src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/133a5b86-8d8c-46b1-9cf8-3fb2a0337619.png"
              fit="fit">
            </el-image>
          </div>
          <div class="bottom_btn" @click="showmore(categoryimg[categoryAactive][categoryBactive].name)">{{
            categoryimg[categoryAactive][categoryBactive].name }}</div>
        </div>
      </div>
    </div>

    <div class="booking">
  
      <div class="bookingform">
        <div class="form">
          <div class="formtitle">我要定制</div>
          <div style="margin: 4px 0;"></div>
          <el-input size="mini" v-model="form.name" placeholder="请输入姓名:"></el-input>
          <div style="margin: 4px 0;"></div>
          <el-input size="mini" v-model="form.phonenum" placeholder="请输入手机号码:"></el-input>
          <div style="margin: 4px 0;"></div>
          <AddressSelect @value="addressValue" v-model="form.address" />
          <div style="margin: 4px 0;"></div>
          <button type="button" class="yubtn" @click="confirm">免费预约</button>
        </div>
      </div>
    </div>


    <div class="hotsale">
      <div class="hotsaletitle">
        <div class="bigtitle">
          热销风格推荐
        </div>
        <div class="smalltitle">
          HOT SALE STYLE RECOMMENDATION
        </div>
      </div>
      <div class="hotcategory">
        <div class="hotitems">
          <div class="hotitem">
            <el-image style="width: 45vw"
              src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-04-13/0702b300-41c9-4d6c-ba1c-aec8573d1a78.jpg"
              fit="contain"></el-image>
            <div class="itemtitle" @click="showmore('中式系列')">中式风格</div>
          </div>
          <div class="hotitem ">
            <div class="itemtitle" @click="showmore('欧式系列')">欧式风格</div>
            <el-image style="width: 45vw"
              src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-04-13/64946efb-fa2a-4dba-bc05-faf9ef9eafa3.jpg"
              fit="contain"></el-image>
          </div>
          <div class="hotitem">
            <el-image style="width: 45vw"
              src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-04-13/7a36c1f0-68cc-4f23-bd62-2b51870d6528.jpg"
              fit="contain"></el-image>
            <div class="itemtitle" @click="showmore('锻打系列')">手工锻打风格</div>
          </div>
        </div>
      </div>
    </div>

    <div class="about">
      <!-- <el-image 
            style="width: 45%;"
            src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-20/e20f2278-011b-4594-bde2-e0ae42651af8.png"
            fit="fit"></el-image> -->
      <div class="aboutimgarea">
        <div class="aboutcontent">
          <div class="contentarea">
            <div class="title">探索英伦风尚</div>
            <div class="content">永康市胡杨工贸是一家集研发、生产、销售、服务为一体的高端铝艺门现代企业；产品有铝艺庭院门、栅栏、阳台扶手等；
              公司引进国内外先进技术以及生产设备、拥有专业的技术团队、完善的售后服务。公司不断自主研发创新，已拥有十余款专利产品，
              收到广大用户的认可......
            </div>
            <div class="more">
              <div class="morecon" @click="barndMore">了解更多</div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutright">
        <div class="aboutnewsarea">
          <div class="spotline" style="font-size: 30px; line-height: 10px; margin-top: 5vw;">---------------------------</div>

          <div v-for="(item, index) in newsList" :key="index" @click="shownews(item.id)">
            <div class="news">
              <div class="date">
                <div class="day">{{ getDay(item.createTime) }}</div>
                <div class="year">{{ getYear(item.createTime) }}</div>
              </div>
              <div class="article">
                <div class="articletitle">{{ item.title }}</div>
                <div class="intro">{{ item.introduction }}</div>
              </div>
            </div>

            <div class="spotline" style="font-size: 30px;line-height: 10px;">---------------------------</div>
          </div>


        </div>
      </div>
    </div>

    <AreaSearchCom />
    <!-- <AreaSearchCom/> -->
    <!-- <div class="areasearch w">
      <div class="left">
        <div class="left1">全国网点查询</div>
        <div class="left2">查看您附近的英伦风尚体验店</div>
      </div>
      <div class="right">
        <div class="first">英伦风尚</div>
        <div class="three">
          <div>工艺</div>
          <div>工匠</div>
          <div>真功夫</div>
        </div>
        <div class="thr">寻找离你最近的那一家体验店</div>
        <div class="cha">查询</div>
      </div>
    </div> -->







  </div>
</template>

<script>
import AreaSearchCom from '@/components/AreaSearchCom.vue';
import AddressSelect from "@/components/address-select.vue";


// eslint-disable-next-line
import { list, getInfo, del, add, update, imageFileUpload } from "@/api/crm/comment/comment.js";
// import { getCurrentScope } from 'vue';
import { list as newSList } from "@/api/crm/newsM/newsM.js";



export default {
  name: 'HomeView',
  components: {
    AreaSearchCom, AddressSelect
},

  data() {
    return {
      form: { category: '我要定制' },
      categoryimg: [
        [
          {
            text: '不同的中式风韵\n以其独有的魅力与文化沉淀\n书写着各自的故事\n它们或精致，或恬静，或威严\n让我们一起走进它们\n去倾听古老文化的声音\n感受古老文明的底蕴',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/96bc0ddf-c479-4533-9e87-abf296e2fd0a.png',
            name: "中式系列",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/e5cddf04-8100-415a-af9e-cbfb7de68b5d.jpg'
          },
          {
            text: '豪华欧式庭院门风格\n特别注重别墅花园\n与居家庭院风格的相和谐。\n人们漫步在花园青石路上，\n感受着周围一草一木的芳香，\n远离城市喧嚣，享受幽静生活。\n这才是真正打造\n别墅花园设计所要实现的高品质生活。\n在设计时注重人与自然的整合，\n还融合了东西方庭院园林理念和手法元素\n真正意义上打造了一份\n属于您所要的舒适生活。',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/d84ef09e-1a86-4db3-97de-f8d6ea7bf646.png',
            name: "欧式系列",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/67856f7e-739f-4999-a580-cc5e1c225df0.jpg'
          },
          {
            text: '采用手工锻打工艺\n造型自然大气\n饱满美观，富有张力\n具有浓厚粗犷的自然味\n在艺术表现上也更具有生命力\n从文化上延续了\n工艺呈现出来的精神内涵',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/28fc7c72-2e40-45df-a4b3-9818a5f225e7.png',
            name: "锻打系列",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/c29fe4b3-1279-4366-83c7-3b522f1b98b2.jpg'
          },
          {
            text: '传承经典\n古补却又生生不息\n这里里外外\n正是一本岁月的史书',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/e58441a4-47f2-4024-bb72-f225d028255a.jpg',
            name: "庭院入户门",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/6708876e-e469-46fd-ad23-697fffe371b9.jpg'
          },
          {
            text: '完美汲取栅栏的黄金比例\n用独特的艺术造型\n包裹住内部的波澜壮阔\n给这片山川湖海限定海岸\n体现匠心之精与刚健之美\n焕新设计，视觉效果和谐美观\n彰显了现代建筑时尚气息\n为居家生活增添无限精彩',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/40844a09-9015-492e-bd8f-d22edfaac879.jpg',
            name: "栅栏系列",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/bae3dfd1-f9c8-4bd4-b44b-b63a2335cce2.jpg'
          },
          {
            text: '用当代建筑笔触\n谱写别样的欧式风情\n在城市的繁华之中\n引领生活之外的风景\n让居住者感受到\n它不仅是人居建筑\n更是心灵的居所\n将内在气质与外在风格进行深度思考\n挖掘出其精神主张\n将生活还给生活',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-19/1e6223f2-b725-4f65-8137-4f65a2069213.jpg',
            name: "扶手系列",
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/5aae4d41-decc-47d0-9740-03ce377c8351.jpg'
          }
        ], [
          {
            text: '凝萃古朴雅致意蕴\n以精湛技艺反复锤炼描摹\n在泛着温润光泽的细腻纹路间\n勾勒内藏的书香气质\n衔接古今艺术精髓\n缔造文韵奢雅的美学空间',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-28/f1d30126-dbef-490d-8ca9-d75df1690fd7.jpg',
            name: "铜板系列",
            //临时
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/e5cddf04-8100-415a-af9e-cbfb7de68b5d.jpg'
          },
          {
            text: '每一道肌理自然天成\n是蹉跎岁月的流露\n是时光雕琢的足迹\n是自然洒脱的粗狂风情\n漫不尽心的肌理\n勾勒着诗境里的浪漫\n营造着独有的素雅格调',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-28/f2b68ae6-b0ff-4fda-b28f-2898a03424cd.jpg',
            name: "岩板系列",
            //临时
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/5aae4d41-decc-47d0-9740-03ce377c8351.jpg'
          },
          {
            text: '融合西方文化艺术\n深化东方美学经典\n以精炼设计笔触\n高端雅致的极致色彩\n细致入微的丝缕肌理\n探索空间与美的平衡\n诠释思艺交融的时代新居',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-28/c2a98a8f-21c7-4d5c-8664-06fd687eb9a0.jpg',
            name: "铝板系列",
            //临时
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/e5cddf04-8100-415a-af9e-cbfb7de68b5d.jpg'
          },
          {
            text: '门里生活，门外境界\n于极简中精细打造\n探索功能与美学的平衡\n维系视觉与体验的协调\n更广泛的视野\n更极致的风尚\n仅在开启瞬间',
            url: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-28/f40c2ceb-0202-4dd2-ad74-b0fea4a2b66c.jpg',
            name: "木板系列",
            //临时
            bigurl: 'https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-26/5aae4d41-decc-47d0-9740-03ce377c8351.jpg'
          }
        ]],
      categoryAactive: 0,
      categoryBactive: 0,

      newsList: null,
    };
  }
  ,
  mounted() {
    this.getNewsList();
  }
  ,
  methods: {
    shownews(id) {
      this.$router.push(
        {
          name: 'news',
          params: { id: id }
        }
      );
    },
    getYear(date) {
      return new Date(date).getUTCFullYear();
    },
    getDay(date) {
      // console.log(date);
      // console.log(new Date(date))
      // console.log(new Date(date).getUTCDate())
      // console.log(    new Date(date).getMonth() +  "   "+new Date(date).getDay())
      return (new Date(date).getMonth() + 1) + '-' + (new Date(date).getDate());
    },
    getNewsList() {

      let queryCompany = {
        'newsCategoryId': 2,
        'pageNum': 1,
        'pageSize': 3
      }
      newSList(queryCompany).then((res) => {
        this.newsList = res.rows;
      })
    }
    ,
    confirm() {
      // console.log(this.form);
      add(this.form).then(res => {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          // eslint-disable-next-line
          callback: action => {
            this.form = { category: '我要定制' }
            this.$message({
              type: 'success',
              message: res.msg
            });
          }
        });
      });
      //
    },
    addressValue(e) {
      console.log(e);
      this.$set(this.form, "address", e.area);
      //此处只为了显示选择；
    },
    switchTab(index) {
      this.categoryAactive = index;
      this.categoryBactive = 0;
    },
    switchSecondT(index) {
      this.categoryBactive = index;
    },
    showmore(xilie) {
      this.$router.push(
        {
          name: 'productSerieView',
          params: { name: xilie }
        }
      );
    },
    barndMore() {
      this.$router.push({ path: 'brand/companyStrength' });
    },
    contact() {
      this.$router.push({ path: 'investment' });
    },
    moreCase() {
      this.$router.push({ path: 'case' });
    },
    branddt() {
      this.$router.push({ path: 'brand/companyNews' });

    }
  }
}
</script>
<style scoped lang="scss">
.home {

  margin: 0;
  padding: 0;


  .series {
    margin: 0;
    padding: 0;

    .series_title {
     

      .series_title_big {
        font-size: 25px;
        font-weight: 700;
      }


      .series_title_small {
        color: #A5A5A5;
      }


    }

    .category {
      margin-top: 70px;
      width: 70%;
      margin: auto;
      display: flex;

      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .btn {
        height: 30px;
        width: 180px;
        text-align: center;
        line-height: 30px;
      }

      .btn:hover {
        background-color: #AF2E0B;
      }

      .cActive {
        background-color: #AF2E0B;
      }

      .line {
        height: 2px;
        background-color: #BCBCBC;
        width: 30%;
      }
    }

    .little_category {
      .ul_imgs {
        display: flex;
        flex-wrap: wrap;
        width: 80%;
        margin: auto;
        margin-top: 50px;
        position: relative;
        z-index: 10;

        li {
          list-style-type: none;
          height: 30vw;
          width: 25vw;
          padding-top: 2vw;
        
        }

        li:hover {
          background-color: #E75A24;
        }

        .active {
          background-color: #E75A24;
        }
      }
    }

    .ul_big_imgs {
      text-align: start;

    
      width: 100%;
      height: 43.22vw;
      display: flex;
      color: white;
      font-size: 9px;

      div {
        margin: auto;
      }

      .ul_big_imgs_part1 {
        white-space: pre-wrap;
      }

      .ul_big_imgs_part2 {
     


        .bottom_btn {
          font-size: 20px;
  
          width: 130px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #D05A24;
        }

        .bottom_btn:hover {
          background-color: black;
        }

      }
    }


  }

  .booking {
    margin: 0;
    padding: 0;
   


    .bookingform {
      width: 100vw;
      height: 55vw;
      background-color: #D05A24;
      display: flex;
      .form {
        text-align: start;
        width: 80vw;
        color: white;
       margin: auto;
        .el-input__inner {
          background-color: #D05A24;
        }

        .formtitle {
          font-size: 20px;
          margin: auto;
          font-weight: 600;
          text-align: center;
        }

        .yubtn {
          font-size: 15px;
          text-align: center;
          color: white;
          width: 23vw;
          height: 10vw;
          background-color: black;
          margin-left: 29vw;
        }
      }
    }
  }

  .hotsale {
    .hotsaletitle {

      height: 15vw;
      background-color: #FFFFFF;

      .bigtitle {
       
        font-size: 20px;
        font-weight: 400;
      }

      .smalltitle {
        margin-top: 10px;
        margin-bottom: 35px;
        font-size: 12px;
        color: #979797;
      }
    }

    .hotcategory {
      height: 120vw;
      background-color: #EFEFEF;


      .hotitems {

        width: 90%;
        margin: auto;
        /* background-color: #AF2E0B; */
        margin-top: auto;
        height: 110vw;
        display: flex;
        flex-direction: column;
        justify-content:space-around;
        .hotitem {
          box-shadow: 0 12px 20px 0 rgba(0, 0, 0, .5);
          display: flex;
          .itemtitle {
            width: 45vw;
            height: 33vw;
            font-size: 20px;
            background-color: #D15A24;
            color: white;
            line-height: 33vw;
          }

          .itemtitle:hover {
            background-color: #222222;
          }

          .hActive {
            background-color: #222222;
          }

        }

      }
    }
  }

  .about {
    text-align: start;


    .aboutimgarea {
      display: flex;

      width: 100vw;
      height: 45vw;
      background-image: url("https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-20/e20f2278-011b-4594-bde2-e0ae42651af8.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;

      .aboutcontent {
        position: relative;
        z-index: 10px;
        margin: auto;
        /* margin-right: -12%; */
        margin-top: 13vw;
        width: 70vw;
        height: 36vw;
        background-color: #FFFFFF;
        border-top-right-radius: 1rem;

        .contentarea {
          width: 70%;
          height: 90%;
          margin-left: 15%;
          
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .title {
            font-size: 19px;
            font-weight: 550;
            color: #505050;


          }

          .content {
            color: #8F8F8F;
            font-size: 8px;
            line-height: 9px;
            /* margin-top: 50px; */
          }

          .more {

            display: flex;
            justify-content: flex-end;
            color: white;


            .morecon {
              text-align: center;
              /* width: 25%; */
              background-color: #D15A24;
              font-size: 14px;
              line-height: 14px;
            }

            .morecon:hover {
              background-color: black;
            }

          }
        }
      }
    }

    .aboutright {
      width: 100%;
      background-color: #222222;
      color: #FEFEFE;
      display: flex;

      /* #BBBBBB */
      .aboutnewsarea {
        margin: 5vw auto;

        .spotline {
          color: #A5A5A5;
        }

        .news {
          display: flex;

          .date {
            .day {
              font-size: 20px;
            }
          }

          .article {
            margin-left: 25px;
           

            .articletitle {
              font-size: 15px;
            }

            .intro {
              color: #A5A5A5;
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  /* .areasearch {
    text-align: start;
    color: white;
    width: 100%;
    height: 20vw;
    background-color: #D05A24;
    display: flex;

    .left {
      margin: auto;

      .left1 {
        font-size: 50px;
        font-weight: 800;
        letter-spacing: 0.3cm;
        padding-left: 100px;
      }

      .left2 {
        font-size: 30px;
        padding-left: 100px;
        font-weight: 50;
      }
    }

    .right {
      margin: auto;


      .first {
        padding-top: 80px;
        font-size: 20px;
      }

      .thr {
        font-size: 15px;
        font-weight: 10;
      }

      .three {
        font-size: 15px;
        font-weight: 10;
        display: flex;
        width: 130px;
        justify-content: space-between;
      }

      .cha {
        margin-top: 30px;
        width: 100px;
        background-color: #AF3E14;
        font-size: 20px;
        text-align: center;
        line-height: 40px;
      }

      .cha:hover {
        background-color: black;
      }
    }
  } */

  .bottombar {
    width: 100%;
    height: 33vw;
    background-color: #1A1A1A;
    display: flex;

    .bars {
      width: 75%;
      margin: auto;
      text-align: start;

      .upbars {
        height: 55px;
        width: 50%;
        display: flex;
        margin-left: 10vw;
        color: #DADADA;

        div {
          margin-right: 60px;
          width: 65px;
          border-bottom: 2px #DADADA solid;
        }
      }

      .downbars {

        width: 50%;
        display: flex;
        margin-left: 10vw;
        margin-top: 20px;
        color: #787878;
        font-size: 14px;

        div {
          margin-right: 60px;
          width: 65px;

          div:hover {
            background-color: white;
          }
        }

      }

      .line_bar {
        height: 1px;
        background-color: #545454;
      }
    }

    .barimg {
      margin: auto;
      color: #9A9A9A;
      div{
        font-size: 10px;
      }
    }

    .endline {
      margin: auto;

      width: 15%;

      .line_bar {
        height: 1px;
        background-color: #545454;
      }
    }
  }

}
</style>
