<template>
  <div class="areasearch ">
    <div class="left">
      <div class="left1">全国网点查询</div>
      <div class="left2">查看您附近的英伦风尚体验店</div>
    </div>
    <div class="right">
      <div class="first">英伦风尚</div>
      <div class="three">
        <div>工艺</div>
        <div>工匠</div>
        <div>真功夫</div>
      </div>
      <div class="thr">寻找离你最近的那一家体验店</div>
      <div class="cha" @click="toYinglun">查询</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AreaSearchCom',
  data(){
    return{
      activeIndex:null,
    }
  },
  methods:{
    toYinglun(){
     // console.log("AreaSearchCom this.activeIndex      "+this.$route.path  )
      if(this.$route.path  != '/store')
      this.$router.push({path:'/store'});
    }
  },
}
</script>
<style scoped lang="scss">
.areasearch {
  text-align: start;
  color: white;
  width: 100%;
  height: 20vw;
  background-color: #D05A24;
  display: flex;

  .left {
    margin: auto;

    .left1 {
      font-size: 10px;
      font-weight: 800;
      letter-spacing: 0.3cm;
      /* padding-left: 100px; */
    }

    .left2 {
      font-size: 10px;
      /* padding-left: 100px; */
      font-weight: 50;
    }
  }

  .right {
    margin: auto;


    .first {
      /* padding-top: 80px; */
      font-size: 10px;
    }

    .thr {
      font-size: 8px;
      font-weight: 10;
    }

    .three {
      font-size: 8px;
      font-weight: 10;
      display: flex;
      /* width: 130px; */
      justify-content: space-between;
    }

    .cha {
      /* margin-top: 30px; */
      /* width: 100px; */
      background-color: #AF3E14;
      font-size: 8px;
      text-align: center;
      /* line-height: 40px; */
    }

    .cha:hover {
      background-color: black;
    }
  }
}
</style>