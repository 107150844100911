<template>
    <div class="product">
        <el-image style="width: 100%"
            src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-02-27/b02e3f84-4d01-4230-b95f-863d6ce66ebe.jpg"
            fit="fit"></el-image>

        <div class="content">

            <div class="serie">
                <div class="serie_title">
                    <div class="up_line"></div>
                    <div>{{ name }}</div>
                </div>
                <div class="serie_content">
                    <div class="item" v-for="(item, index) in list" :key="index" @click="showdetai(item.id)">
                        <div>
                            <el-image style="width: 100%;" :src=item.titleImgUrl fit="fit"></el-image>
                        </div>
                        <div>{{ item.title }}</div>
                    </div>

                </div>
            </div>

            <div style="font-size: 30px;">
                <el-pagination background layout="prev, pager, next" :page-size="9" :total=tot
                    @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
        <AreaSearchCom />
    </div>
</template>

<script>
import AreaSearchCom from '@/components/AreaSearchCom.vue';
import { list } from "@/api/crm/product/product.js";
import { list as categoryList } from "@/api/crm/product/productCategoryInfo.js";

export default {
    name: 'ProductSerieView',
    data() {
        return {
            name: '',
            pageNum: 1,
            pageSize: 8,
            newsCategoryId: null,
            categoryList: null,
            list: null,
            tot: 1000,
        };
    },
    mounted() {
        //this.getList();
        this.getCategoryList();
    },
    methods: {
        showdetai(id) {
            this.$router.push(
                {
                    name: 'productSingleView',
                    params: { id: id }
                }
            );
        }, handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getList();
        },
        getCategoryList() {
            categoryList().then((res) => {
                this.categoryList = res.data;
                console.log(this.categoryList);


                // for (let item of this.categoryList) {
                //     if(item.name===this.name){
                //         this.newsCategoryId =item.id;
                //         break;
                //     }
                // } 
                for (let i = 0; i < res.data.length; i++) {
                    console.log(this.categoryList[i].name);
                    if (this.categoryList[i].name === this.name) {
                        this.newsCategoryId = this.categoryList[i].id;
                        break;
                    }
                }
                console.log(this.newsCategoryId);

                this.getList();
            });
        },
        getList() {
            let query = {
                'newsCategoryId': this.newsCategoryId,
                'pageNum': this.pageNum,
                'pageSize': this.pageSize
            }
            console.log(query)
            list(query).then((res) => {
                this.list = res.rows;
                this.tot = res.total;
                //console.log(this.tot);
            });
        }
    },
    components: { AreaSearchCom },
    created() {
        // console.log(this.$route.params.name);
        this.name = this.$route.params.name;
        //查询是哪个系列；然后分页显示产品
    }
}
</script>
<style scoped lang="scss">
.product {
    margin: 0;
    padding: 0;


    .content {
        .serie {
            padding-top: 20px;

            .serie_title {
                width: 80%;
                text-align: start;
                display: flex;
                margin: auto;
                margin-bottom: 20px;
                font-size: 20px;

                .up_line {
                    width: 4px;
                    height: 26px;
                    background-color: #D05A24;
                    margin-right: 15px;
                }
            }

            .serie_content {
                margin: auto;
                width: 80vw;
                display: flex;

                flex-wrap: wrap;
                padding-bottom: 30px;
                row-gap: 60px;

                .item {
                    width: 40vw;
                }
            }
        }



    }
}
</style>