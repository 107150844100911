<template>
    <div class="CompanyStrengthView">
        <div class="part1">
            <div>{{ yearNum }}年发展见证品牌实力</div>
            <div style="margin-top: -20px;margin-bottom: 20px;">__</div>
        </div>

        <div class="part2">
            <div class="title">成立，英伦风尚</div>
            <div class="years">
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
                <div>
                    <div>2016</div>
                    <div>年</div>
                </div>
            </div>
        </div>
        <div class="part3">
            <div>发展规模</div>
            <div style="margin-top: -10px;">__</div>
            <div class="small">202个城市35000名客户参与调研，无数个选择庭院门的考量引我们研发的方向</div>
            <el-image style="width: 50%"
                src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/db496761-9894-4b87-bbfe-67563618e16f.png"
                fit="fit"></el-image>
        </div>
        <div class="part1">
            <div>科技强企硬实力</div>
            <div style="margin-top: -20px; margin-bottom: 20px;">__</div>
        </div>
        <div class="part4">
            <div class="show">
                <div class="content">
                    <div class="loc">
                        <div class="title4">武汉分公司200余亩厂区</div>
                        <div>
                            是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英
                        </div>
                        <div>___</div>
                    </div>
                </div>
                <el-image style="width: 40%;"
                    src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/f92009ce-63dc-4cd8-962b-f7a45bf248ae.png"
                    fit="contain"></el-image>
            </div>

            <div class="show2">
                <el-image style="width: 60%;"
                    src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/a850ab64-36c1-4cf6-868a-636792ba9e10.png"
                    fit="contain"></el-image>
                <div class="content">

                    <div class="loc">
                        <div class="title4">永康总公司200余亩厂区</div>
                        <div >
                            是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英
                        </div>
                        <div>___</div>
                    </div>
                </div>

            </div>


            <div class="show">
                <div class="content">
                    <div class="loc">
                        <div class="title4">11000平方英伦风尚新展厅</div>
                        <div>
                            是非成败转头空，青山依旧在，惯看秋月春风。一壶浊酒相逢古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空青山依旧在，惯看秋月春风。一壶浊酒赛相逢，古今多少事，滚滚长江东逝水，浪花淘尽英是非成败转头空，青山依旧在，看秋月春风。一壶浊酒喜相逢，古今多少事，滚滚长江东逝水，浪花淘尽英
                        </div>
                        <div>___</div>
                    </div>
                </div>
                <el-image style="width: 40%;"
                    src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/7bf1d3f4-e874-41c8-8ac4-9555637a5d18.png"
                    fit="contain"></el-image>
            </div>



            <div class="show2">
                <el-image style="width: 60%;"
                    src="https://shanghaiaoying.oss-cn-hangzhou.aliyuncs.com/shayPhoto/2024-03-04/226ab032-9b74-42b6-ad3a-547891181690.png"
                    fit="contain"></el-image>
                <div class="content">

                    <div class="loc">
                        <div class="title4">机器设备</div>
                        <div>
                            公司斥巨资从德国、意大利等国家引进全套生产设备，主要设备选用童大利5CM和BESSE机械设备及德国豪迈HOMAG进口设备设备精度达到02mm，产品制造一体化，采用一流工艺技术，是目前国内具实力的整体木作服务商之一。
                        </div>
                        <div>___</div>
                    </div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: 'CompanyStrengthView',
    data() {
        return {
            yearNum: "",
        }
    },
    mounted() {
        this.yearNum = new Date().getFullYear() - 2015;
    }
}
</script>
<style scoped lang="scss">
.CompanyStrengthView {
    .part1 {
        font-size: 20px;
        height: 9vw;
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        div {
            margin: auto;
        }
    }

    .part2 {
        display: flex;
        flex-direction: column;
        background-color: #EDEDED;
        height: 18vw;

        .title {
            margin: auto;
            font-size: 14px;
            flex: 1;
            line-height: 9vw;
            color: #767676;
        }

        .years {
            font-size: 10px;
            flex: 1;
            border-top: solid #D66C3D;
            width: 80%;
            margin: auto;
            display: flex;

            div {

                width: 16%;

                div {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    .part3 {
        height: 50vw;
        background-color: #EDEDED;
        font-size: 15px;
        /* padding-top: 50px; */

        .small {
            font-size: 8px;
            margin-top: 10px;
        }
    }

    .part4 {
        .show {
            display: flex;

            .content {
                width: 60%;
                background-color: #D7D7D7;
                display: flex;

                .loc {
                    width: 50%;
                    margin: auto;
                    text-align: start;
                    font-size: 5px;

                    .title4 {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }
        .show2 {
            display: flex;

            .content {
                width: 40%;
                background-color: #D7D7D7;
                display: flex;

                .loc {
                    width: 50%;
                    margin: auto;
                    text-align: start;
                    font-size: 5px;

                    .title4 {
                        font-size: 12px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}
</style>